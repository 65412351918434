<template>
  <div :style="slidePosition" class="z-6 w-100 h-100">
    <div id="cubes2Green" class="position-absolute"
      style="height:100vh; width:100vh;left:100%;transform:translateX(-100%)">
      <canvas class="w-full h-full" id="cubes2GreenCanvas"></canvas>
    </div>
  </div>
</template>

<script>
import anime from "animejs"
//import { normalize } from "@/utils/ranges"

import { slidePositionParallax } from "@/utils/slidePositioning"

import * as THREE from "three"

export default {
  data() {
    return {}
  },
  methods: {
    canvasMove(percentage) {
      let animation = anime
        .timeline({
          autoplay: false,
          loop: false,
          endDelay: 1000
        })
        .add({
          targets: "#cubes2Green",
          left: ["100%", "32.5%"],
          easing: "easeInOutSine",
          duration: 500,
          delay: 1000
        })
      /* var progress =
        percentage <= 0.5 ? percentage * 2 : normalize(percentage, 0.5, 1, 1, 0)
      animation.seek(animation.duration * progress) */
      animation.seek(animation.duration * percentage)
    }
  },
  watch: {
    innerPosition() {
      this.canvasMove(this.innerPosition)
    }
  },
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionParallax(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },
  mounted() {
    const canvasContainer = document.getElementById("cubes2Green")
    const canvas = document.getElementById("cubes2GreenCanvas")
    var width = canvasContainer.scrollWidth
    var height = canvasContainer.scrollHeight

    var centerX = canvasContainer.scrollWidth / 2
    var centerY = canvasContainer.scrollHeight / 2

    var renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
      gammaFactor: 2.2,
      powerPreference: "high-performance",
      outputEncoding: THREE.sRGBEncoding,
      canvas: canvas
    })
    renderer.setPixelRatio(
      window.devicePixelRatio > 2 ? 2 : window.devicePixelRatio
    )
    //renderer.setPixelRatio(window.devicePixelRatio)

    renderer.setSize(width, height)

    var scene = new THREE.Scene()

    var cubeGeometry0 = new THREE.BoxBufferGeometry(
      width / 3,
      width / 3,
      width / 3,
      4,
      4,
      4
    )

    var GreenMaterialTexture = new THREE.MeshLambertMaterial({
      color: 0x64ce51
    })

    var cube0 = new THREE.Mesh(cubeGeometry0, GreenMaterialTexture)
    var cube1 = new THREE.Mesh(cubeGeometry0, GreenMaterialTexture)
    var cube2 = new THREE.Mesh(cubeGeometry0, GreenMaterialTexture)

    var camera
    cube0.rotation.x = (Math.PI * 45) / 180
    cube0.rotation.y = (Math.PI * 45) / 180
    cube0.rotation.z = (Math.PI * 45) / 180
    cube0.position.x = centerX + Number(width * 0.45)
    cube0.position.y = centerY + Number(height * 0.35)
    cube0.position.z = 500
    scene.add(cube0)

    cube1.rotation.x = (Math.PI * 45) / 180
    cube1.rotation.y = (Math.PI * 45) / 180
    cube1.rotation.z = (Math.PI * 45) / 180
    cube1.position.x = centerX - Number(width * 0.15)
    cube1.position.y = centerY + Number(height * 0.1)
    cube1.position.z = 450
    scene.add(cube1)

    cube2.rotation.x = (Math.PI * 45) / 180
    cube2.rotation.y = (Math.PI * 45) / 180
    cube2.rotation.z = (Math.PI * 45) / 180

    cube2.position.x = centerX + Number(width * 0.35)
    cube2.position.y = centerY - Number(height * 0.75)
    cube2.position.z = 300
    scene.add(cube2)

    camera = new THREE.PerspectiveCamera(85, width / height, 0.1, 2000)

    camera.position.x = centerX
    camera.position.y = centerY
    /* console.log(
      "SCREEN AVAIL HEIGHT",
      window.screen.availWidth > 992,
      window.screen.availWidth
    ) */
    if (window.screen.availWidth > 1280) {
      camera.position.z = 1650
    } else if (window.screen.availWidth > 992) {
      camera.position.z = 1250
    } else {
      camera.position.z = 850
    }

    scene.add(camera)

    var pointLight = new THREE.PointLight(0xdbdbdb)
    pointLight.position.set(width / 3, height * 1.5, 1800)

    scene.add(pointLight)

    const light = new THREE.AmbientLight(0x686868) // soft white light
    scene.add(light)

    var clock0 = new THREE.Clock()
    var clock1 = new THREE.Clock()
    var clock2 = new THREE.Clock()

    scene.background = null
    function render() {
      requestAnimationFrame(render)

      scene.background = null
      renderer.setClearColor(0xffffff, 0)
      cube0.rotation.y -= clock0.getDelta()
      cube1.rotation.y -= clock1.getDelta()
      cube2.rotation.y -= clock2.getDelta()

      renderer.render(scene, camera)
    }

    render()
  },
}
</script>
<style lang="scss" scoped>
canvas {
  width: 100%;
  height: 100%;
  color: #64ce51;
}

.z-99 {
  z-index: 999;
}
</style>
